import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const SplitOne = () => {
  return (
    <div id="about-us" className="rn-splite-style">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail image-left-content">
              <img
                src="./images/about/bg-image-about-us.png"
                alt="split Images"
              />
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title">
                  Rewards, Loyalty and Ancillary Revenue
                </h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description text-white">
                  The aviation travel market soared to over $2.29 Trillion in
                  2023, with continued growth into 2024. Altitude Rewards offers
                  a technology-driven solution to enhance ancillary revenue for
                  airlines worldwide.
                </p>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <ul className="split-list text-white">
                  <li>Integrates with airline apps and digital wallets.</li>
                  <li>
                    Features a branded digital rewards card for each airline.
                  </li>
                  <li>
                    Enables usage tracking and report generation for airlines.
                  </li>
                </ul>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description text-white">
                  Its accessibility through digital wallets enhances lifestyle
                  benefits for passengers, making rewards program usage seamless
                  and more engaging.
                </p>
              </ScrollAnimation>
              {/* <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <div className="view-more-button mt--35">
                  <a className="btn-default" href="/contact">
                    Learn More
                  </a>
                </div>
              </ScrollAnimation> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplitOne;
