import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import ScrollAnimation from "react-animate-on-scroll";

const Data = [
  {
    countNum: 100,
    countTitle: "Billion in Global Ancillary Revenue",
  },
  {
    countNum: 15,
    countTitle: "Average Revenue from Ancillaries",
  },
  {
    countNum: 50,
    countTitle: "Total Ancillary Revenue for Some Airlines",
  },
  {
    countNum: 1,
    countTitle:
      "Million Potential Additional Annual Earnings For Every 2 Million Passengers",
  },
];
const CounterUpOne = ({ textALign, counterStyle, column }) => {
  return (
    <div className="row">
      {Data.map((data, index) => (
        <div className={`${column}`} key={index}>
          <div className={`count-box ${counterStyle} ${textALign}`}>
            <TrackVisibility once>
              {({ isVisible }) =>
                isVisible && (
                  <div className="count-number">
                    {isVisible ? (
                      <>
                        <CountUp end={data.countNum} />
                        <span
                          //   className="gold-color"
                          style={{ color: "#b89c4c" }}
                        >
                          {index === 0 || index === Data.length - 1 ? "+" : "%"}
                        </span>
                      </>
                    ) : (
                      0
                    )}
                  </div>
                )
              }
            </TrackVisibility>
            <h5 className="title">{data.countTitle}</h5>
          </div>
        </div>
      ))}
    </div>
  );
};
export default CounterUpOne;
