import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";

const Contact = () => {
  return (
    <>
      <SEO title="Contact || Altitude Rewards - Elevate Your Journey" />
      <Layout>
        {/* <BreadcrumbOne
          title="Connect With Altitude Rewards <br /> Enhance Your Ancillary Revenue."
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Contact"
        /> */}
        <div className="main-content">
          <div className="rwt-contact-area rn-section-gap bg-color-blackest">
            <div className="container">
              <SectionTitle
                textAlign="text-center"
                radiusRounded=""
                subtitle="Get in Touch"
                title="Ready to Elevate Your Airline's Revenue?"
                description="Reach out to discover how Altitude Rewards can transform your airline's ancillary revenue strategy. Let's discuss tailored solutions that fit your needs."
              />
              <ContactOne />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default Contact;
