import PropTypes from "prop-types";
import React from "react";

const SEO = ({ title }) => {
  return (
    <>
      <meta charSet="utf-8" />
      <title>
        {title} | Altitude Rewards - Revolutionizing Airline Ancillary Revenue
      </title>
      <meta name="robots" content="index, follow" />
      <meta
        name="description"
        content="Altitude Rewards offers innovative solutions to airlines seeking to boost ancillary revenue through a comprehensive rewards program. Enhance your loyalty schemes and elevate passenger experience with our tailored digital platform."
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta
        name="keywords"
        content="ancillary revenue, airline revenue, airline rewards program, airline loyalty program, digital wallet for airlines, enhance passenger experience"
      />
    </>
  );
};
SEO.propTypes = {
  title: PropTypes.string,
};

export default SEO;
