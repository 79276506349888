import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";

import HeaderOne from "../common/header/HeaderOne";
import Copyright from "../common/footer/Copyright";
import Slider from "react-slick";
import { BannerActivation } from "../utils/script";
import Separator from "../elements/separator/Separator";
import SlpitOne from "../elements/split/SlpitOne";
import ValueToAirlines from "../elements/ValueToAirlines";
import CalltoActionSeven from "../elements/calltoaction/CalltoActionSeven";
import CounterUpOne from "../elements/counterup/CounterUpOne";
import CounterUpTwo from "../elements/counterup/CounterUpTwo";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import SectionTitle from "../elements/sectionTitle/SectionTitle";

const BannerData = [
  {
    image: "/images/bg/bg-intro.png",
    title: "Elevating Ancillary Revenue Airline by Airline",
    description:
      "With over 10 million daily passengers and growing, the aviation industry seeks competitive edges. Ancillary revenue, powered by Altitude Rewards, unlocks unparalleled profitability and growth opportunities.",
    styles: {
      backgroundPosition: "30% 50%",
    },
  },
  {
    image: "/images/bg/bg-who-we-are.jpg",
    title: "Rewards, Loyalty, and Ancillary Revenue",
    description:
      "In an industry worth over $2.29 Trillion, Altitude Rewards emerges as a pivotal ancillary revenue tool, seamlessly integrating into airline apps and digital wallets to enhance passenger loyalty and experience.",
    styles: {
      backgroundPosition: "center center",
    },
  },
  {
    image: "/images/bg/bg-value.jpg",
    title: "Ancillary Revenue: Key to Airline Growth",
    description:
      "Generating over $100B annually, ancillary revenue forms the cornerstone of airline profitability. Altitude Rewards elevates this potential, supporting loyalty programs and unlocking new revenue channels.",
    styles: {
      backgroundPosition: "80% 50%",
    },
  },
  {
    image: "/images/bg/bg-revenue.jpg",
    title: "Expand Your Earnings",
    description:
      "Embrace ancillary revenue by personalizing the travel experience for your passengers, with Altitude Rewards leading the way in innovative revenue solutions and enhanced passenger engagement.",
    styles: {
      backgroundPosition: "center center",
    },
  },
];

const Home = () => {
  return (
    <>
      <SEO title="Home" />
      <main className="page-wrapper">
        <HeaderOne btnStyle="btn-small" HeaderSTyle="header-transparent" />

        {/* Start Slider Area  */}
        <Slider
          className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow"
          {...BannerActivation}
        >
          {BannerData.map((data, index) => (
            <div key={index} className="single-slide">
              <div
                className="height-950 bg_image"
                data-black-overlay="5"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`,

                  ...data.styles,
                }}
              >
                <div className="container">
                  <div className="row row--30 align-items-center px-2">
                    <div className="col-12">
                      <div className="inner text-center">
                        <h1
                          className="title"
                          dangerouslySetInnerHTML={{ __html: data.title }}
                        ></h1>
                        <p
                          className="description text-white"
                          dangerouslySetInnerHTML={{ __html: data.description }}
                        ></p>
                        <div className="button-group mt--30">
                          <a className="btn-default" href="#about-us">
                            Learn More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>

        <Separator />
        <div className="about-area about-style-4 rn-section-gap">
          <div className="container">
            <div className="row mt_dec--30 row--15">
              <SlpitOne />
            </div>
          </div>
        </div>
        <Separator />
        <ValueToAirlines />
        <div className="rwt-counterup-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Revenue Insights"
                  title="Industry Overall Ancillary Statistics"
                  description=""
                />
              </div>
            </div>
            <CounterUpOne
              column="col-lg-3 col-md-6 col-sm-6 col-12"
              counterStyle="counter-style-1"
              textALign="text-center"
            />
          </div>
        </div>

        <Separator />

        <CalltoActionSeven />
        <Copyright />
      </main>
    </>
  );
};

export default Home;
