import React from "react";
// import { Link } from "react-router-dom";
import CalltoActionSeven from "../../elements/calltoaction/CalltoActionSeven";
// import footerOne from "../../data/footer/footerOne.json";

const FooterTwo = () => {
  return (
    <>
      <footer className="rn-footer footer-style-default variation-two">
        <CalltoActionSeven />
      </footer>
    </>
  );
};

export default FooterTwo;
