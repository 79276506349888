import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import ScrollAnimation from "react-animate-on-scroll";

const ValueToAirlines = () => {
  return (
    <div
      id="value-to-airlines"
      className="rn-splite-style about-area about-style-4 rn-section-gap bg-color-blackest"
    >
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="order-2 order-lg-1 col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title">
                  Ancillary Revenue Key to Airline Growth
                </h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description text-white">
                  Ancillary revenue is crucial, accounting for over $100B
                  annually across the industry. With an average of 15% of total
                  revenue from ancillaries, Altitude Rewards amplifies this by
                  supporting loyalty programs and enhancing passenger
                  experience.
                </p>
              </ScrollAnimation>

              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <ul className="feature-list text-white mb-5">
                  <li>• Increases potential ancillary revenue</li>
                  <li>• Supports loyalty, miles, and reward programs</li>
                  <li>• Enhances the passenger experience</li>
                  <li>• Builds brand awareness</li>
                </ul>
              </ScrollAnimation>
            </div>
          </div>
          <div className="order-1 order-lg-2 col-lg-12 col-xl-6 col-12">
            <div className="thumbnail">
              <img
                src="./images/split/value-to-airlines.png"
                alt="split Images"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ValueToAirlines;
